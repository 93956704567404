import socratesPicture from '../../assets/img/socrates2014_about_wall.jpg';
import { LogoColumn } from '../common/LogoColumn';
import Schedule from './Schedule';

export default function Training() {
  return (
    <div id="training" className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header">
            <h1>Training Day</h1>
          </div>
          <div className="extraspace">
            <img
              className="img-fluid"
              src={socratesPicture}
              alt="A session at SoCraTes."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-xs-12">
                <div>
                  <ul>
                    <li>
                      Are you new to the software industry, and want to get a
                      head start?
                    </li>
                    <li>
                      Have you been practicing for a while, but always thought
                      there had to be some deeper method to it?
                    </li>
                    <li>
                      Have you heard about test-driven development, refactoring,
                      or connascence, and always wanted to learn more about
                      them?
                    </li>
                  </ul>
                </div>
                <div className="segment-header">
                  <h3>An extra day</h3>
                </div>
                <div>
                  <p>
                    The purpose of the training day is to open up and extend the
                    world of crafting - for newcomers and more experienced
                    people alike. And to provide an easy way to have all the
                    questions you will probably arrive with answered by
                    experienced practitioners.
                  </p>
                  <p>
                    Unlike the rest of the conference, this day does not follow
                    the{' '}
                    <a
                      href="https://en.wikipedia.org/wiki/Open_Space_Technology"
                      title="Learn about Open Space Technology"
                    >
                      OpenSpace Technology
                    </a>{' '}
                    format.
                  </p>
                  <p>
                    For our training day, we have four tracks with a variety of
                    sessions. Some are aimed at beginners and some at more
                    experienced people, but they aren't strictly divided. You
                    can pick and choose any sessions from any track to make a
                    program that suits your needs.
                  </p>
                  <p>
                    Sessions will be taught by tenured and distinguished
                    community members with years of practical experience.
                  </p>
                  <p>
                    And while we are busy with putting together this year's
                    exciting schedule, please find below last year's edition to
                    get a first impression of what training day at SoCraTes can
                    offer to you.
                  </p>

                  <Schedule />
                </div>
                <div className="segment-header">
                  <h3>Can I still join the OpenSpace?</h3>
                </div>
                <div>
                  <p>
                    We welcome practitioners and learners at all levels at
                    SoCraTes - because we believe that sharing, teaching and
                    learning are equally important to the community health, and
                    that this is a fundamental part of what makes SoCraTes the
                    magical place it is. Training Day is intended to build
                    bridges and make it easier to join the community, not to
                    create an extra entry barrier. As a consequence,{' '}
                    <strong>
                      you can only book a ticket to Training Day as an add-on to
                      the full SoCraTes conference
                    </strong>
                    .
                  </p>
                </div>
                <div className="segment-header">
                  <h3>Cost</h3>
                </div>
                <div>
                  <p>
                    Training Day is <strong>not</strong> part of the regular
                    SoCraTes programme. It requires both extra preparation -
                    especially for the session hosts - and additional cost. As a
                    consequence, you will have to pay a cover charge of 100
                    &euro; to attend.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <LogoColumn />
        </div>
      </div>
    </div>
  );
}
