import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  diversitySelected: string;
  onDiversityChange: (arg: string) => void;
};

type State = {
  isShowingDetailsForOther: boolean;
  detailsForOther: string;
};
type PropsForDetails = {
  hasValidValue: boolean;
  onChange: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

function DetailsForOtherInput(props: PropsForDetails) {
  const klass = props.hasValidValue ? 'is-valid' : 'is-invalid';
  return (
    <div>
      <label htmlFor="detailsForOther" className="col-form-label">
        Please give a (very) short explanation - just so we know.
      </label>
      <input
        type="text"
        name="detailsForOther"
        className={`form-control ${klass}`}
        value={props.value}
        id="detailsForOther"
        onChange={props.onChange}
        required
        placeholder="Details"
      />
    </div>
  );
}

export class DiversityInput extends React.Component<Props, State> {
  static propTypes = {
    diversitySelected: PropTypes.string.isRequired,
    onDiversityChange: PropTypes.func.isRequired,
  };
  state = {
    isShowingDetailsForOther: false,
    detailsForOther: '',
  };
  isValid = (detailsForOther: string) => detailsForOther.trim().length > 0;
  onDiversityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isShowingDetailsForOther = value === 'other';
    this.setState({
      isShowingDetailsForOther,
    });

    if (isShowingDetailsForOther) {
      this.props.onDiversityChange(this.state.detailsForOther);
    } else {
      this.props.onDiversityChange(value);
    }
  };
  onDiversityDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const detailsForOther = event.target.value;
    this.setState({
      detailsForOther,
    });
    this.props.onDiversityChange(detailsForOther);
  };

  render() {
    const OPTIONS = [
      {
        value: 'no',
        label: 'No',
      },
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'other',
        label: 'No (or not exactly), but...',
      },
    ];

    const isChecked = (value: string) =>
      value === this.props.diversitySelected ||
      (value === 'other' && this.state.isShowingDetailsForOther);

    return (
      <div>
        <div className="form-group col">
          <label htmlFor="diversity" className="col-form-label">
            Are you a member of any of the groups listed above?
          </label>
          <div className="form-inline">
            {OPTIONS.map(({ value, label }) => (
              <div key={value} className="form-check form-check-inline">
                <input
                  checked={isChecked(value)}
                  id={value}
                  name="diversity"
                  onChange={this.onDiversityChange}
                  type="radio"
                  value={value}
                  required
                  className="form-check-input"
                />
                <label htmlFor={value} className={`diversity-${value}`}>
                  <b>&nbsp;{label}</b>
                </label>
              </div>
            ))}
          </div>
          {this.state.isShowingDetailsForOther && (
            <DetailsForOtherInput
              onChange={this.onDiversityDetailsChange}
              value={this.state.detailsForOther}
              hasValidValue={this.isValid(this.state.detailsForOther)}
            />
          )}
        </div>
      </div>
    );
  }
}
